var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-card',{staticStyle:{"margin-left":"0.2%","margin-right":"0.2%","margin-top":"2%","font-family":"'Montserrat'","border-radius":"10px 10px 10px 10px","background-color":"rgb(235,235,235)"},attrs:{"width":"99.6%","max-height":"15000"}},[_c('div',[_c('v-card',[_c('v-card-title',{staticStyle:{"font-size":"17px"}},[_c('v-spacer'),_c('v-spacer'),_c('b',[_vm._v(" Reservaciones especiales")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('br')],1),_c('br'),_c('v-card-text',[_c('v-btn',{staticStyle:{"float":"right","color":"white","padding":"20px"},attrs:{"color":"green"}},[_vm._v("Agregar hotel")])],1),_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"font-size":"13px"},attrs:{"headers":_vm.headers,"items":_vm.desserts,"single-select":_vm.singleSelect,"search":_vm.search,"item-key":"clave","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-switch',{staticClass:"pa-3",attrs:{"label":"Single select"},model:{value:(_vm.singleSelect),callback:function ($$v) {_vm.singleSelect=$$v},expression:"singleSelect"}})]},proxy:true},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":"/detallespecial"}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#818181","font-size":"14px","border":"1px solid #818181","border-radius":"100px"},attrs:{"small":""}},[_vm._v(" fas fa-ellipsis-h ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.clave))])],1)]}},{key:"item.agencia",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.agencia))])]}},{key:"item.hotel",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.hotel))])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.admin))])]}},{key:"item.agency",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",staticStyle:{"font-size":"14px"},attrs:{"small":"","color":_vm.getColoriCON(item.agency)}},[_vm._v(" fas fa-dollar-sign ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.agency)))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.formatNumber(item.total)))])]}},{key:"item.hortumex",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",staticStyle:{"font-size":"14px"},attrs:{"small":"","color":_vm.getColoriCON(item.hortumex)}},[_vm._v(" fas fa-dollar-sign ")]),_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.hortumex)))])]}},{key:"item.salida",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.salida)))])]}},{key:"item.Usuario",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(item.Usuario))])]}},{key:"item.llegada",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.llegada)))])]}},{key:"item.creacion",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.reformatDate(item.creacion)))])]}},{key:"item.calories",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"font-size":"10px"},attrs:{"color":_vm.getColor(item.calories),"dark":""}},[_vm._v(" "+_vm._s(item.calories)+" ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }